import React, {Component} from 'react';
import './Shops.css';
import Slider from "react-slick";

import ShopsData from './Shops.json';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as shopLocationActions from '../actions/shopLocationActions.js';

const settings2 = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    draggable: true,
    responsive: [
        {
            breakpoint: 430,
            settings: {
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 770,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
}

class Shops extends Component{

    componentDidMount() {
        this.props.actions.getAllLocations();
    }

    returnSettings(){
        let settings;

        if(this.props.shopLocations.length < 4){
            settings = {
                dots: false,
                arrows: false,
                infinite: true,
                slidesToShow: this.props.shopLocations.length,
                slidesToScroll: 1,
                autoplay: false,
                speed: 1000,
                draggable: true,
                responsive: [
                    {
                        breakpoint: 430,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: this.props.shopLocations.length,
                        }
                    },
                    {
                        breakpoint: 770,
                        settings: {
                            slidesToShow: 2,
                        }
                    }
                ]
            }
            return settings;
        } else {
            settings = {
                    dots: false,
                    arrows: false,
                    infinite: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    autoplay: false,
                    speed: 1000,
                    draggable: true,
                    responsive: [
                        {
                            breakpoint: 430,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 1025,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 770,
                            settings: {
                                slidesToShow: 2,
                            }
                        }
                    ]
            }
            return settings;
        }
    }

    renderSlider(){
        if(!this.props.shopLocations){
            console.log("brakdanych")
            return(
                <Slider ref={shopsSlider =>(this.shopsSlider = shopsSlider)} {...settings2}>
                    {ShopsData.map((ShopsD, index) =>{
                        return(
                            <div key={index} className={"shop"}>
                                <h3 className={"shop-item-first"}>{ShopsD.name}</h3>
                                <p className={"shop-item"}>{ShopsD.city}</p>
                                <p className={"shop-item"}>{ShopsD.street}</p>
                            </div>
                        )
                    })}
                </Slider>
            )
        } else {
            return(
                <Slider ref={shopsSlider =>(this.shopsSlider = shopsSlider)} {...this.returnSettings()}>
                    {this.props.shopLocations.map((ShopsD, index) =>{
                        return(
                            <div key={index} className={"shop"}>
                                <h3 className={"shop-item-first"}>{ShopsD.name}</h3>
                                <p className={"shop-item"}>{ShopsD.city}</p>
                                <p className={"shop-item"}>{ShopsD.address}</p>
                            </div>
                        )
                    })}
                </Slider>
            )

        }
    }

    render(){
        return (
            <div className={"shops-container"}>
                <div className={"ico-shops"}></div>
                <div className={"shops-txt"}>Lista punktów usługowych biorących udział w akcji:</div>
                <div className={"shops"}>
                    <div className={"slider-shops-controls-arrow prev"} onClick={() => {this.shopsSlider.slickPrev()}}></div>
                    <div className={"shops-slider-container"}>
                        {this.renderSlider()}
                    </div>
                    <div className={"slider-shops-controls-arrow next helper"} onClick={() => {this.shopsSlider.slickNext()}}></div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(shopLocationActions, dispatch)
    }
}

const mapStateToProps = (state) => {
    return {
        shopLocations : state.shopLocation.shopLocations
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shops);