import axios from 'axios';

export function getAllLocations(){
    return {
        type: "FETCH_SHOP_LOCATIONS",
        payload: axios.get(`http://localhost:8080/sl/all`)
    }
}

export function deleteLocation(id){
    return {
        type: "DELETE_SHOP_LOCATION",
        payload: axios.delete(`http://localhost:8080/sl/delete/${id}`, {
            'headers': {
                'Authorization': localStorage.getItem('token')
            }
        })
    }
}

export function createLocation(shopLocation){
    return {
        type: "CREATE_SHOP_LOCATION",
        payload: axios.post(`http://localhost:8080/sl/create`, shopLocation, {
            'headers': {
                'Authorization': localStorage.getItem('token')
            }
        })
    }
}