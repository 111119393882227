import React, {Component} from 'react';
import './SliderComponent.css';

import Slider from 'react-slick';

class SliderComponent extends Component{
    constructor(){
        super();
        this.state = {
            counter: 0,
            busy: false,
        }
        this.nextSlide = this.nextSlide.bind(this);
        this.prevSlide = this.prevSlide.bind(this);
    }


    getText(whichOne){
        switch(whichOne){
            case 0:
                return (<pre>Przejrzysta mapa pokaże Ci, <br/>Gdzie czekają na Ciebie wirtualne atrakcje(gry i zadania)</pre>)
            case 1:
                return (<pre>Wykonuj tam ciekawe zadania (gry, układanki itd.) <br/> Szczegółowe zasady znajdziesz w poszczególnych grach.</pre>)
            case 2:
                return (<pre>Po przejściu wszystkich lokalizacji czeka na Ciebie <br/> nagroda! Jest nią UNIKALNY KOD PROMOCYJNY. <br/>Gdy pokażesz KOD w wybranych punktach usługowych <br/> - dostaniesz miły RABAT!</pre>)
            default:
                return (<pre>Przejrzysta mapa pokaże Ci, <br/>Gdzie czekają na Ciebie wirtualne atrakcje(gry i zadania)</pre>)
        }
    }

    nextSlide(){
        if(this.state.busy === true) return;
        this.setState({busy: true});
        setTimeout(() => this.setState({counter: this.state.counter + 1}), 300);
        setTimeout(() => {this.setState({busy: false})}, 500);
        this.slider.slickNext();
        this.phoneSlider.slickNext();
    }

    prevSlide(){
        if(this.state.busy === true) return;
        this.setState({busy: true});
        if(this.state.counter === 0){
            setTimeout(() => this.setState({counter: 2}), 300);
            setTimeout(() => {this.setState({busy: false})}, 500);
        } else {
            setTimeout(() => this.setState({counter: this.state.counter - 1}), 300);
            setTimeout(() => {this.setState({busy: false})}, 500);
        }
        this.slider.slickPrev();
        this.phoneSlider.slickPrev();
    }

    render(){
        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false,
        };
        const settingsPhone = {
            dots: false,
            arrows: false,
            infinite: true,
            fade: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false,
        };
        return (
            <div className={"slider-component-container"}>
                <div className={"slider-image-container"} >
                    <Slider ref={slider => (this.slider = slider)} {...settings}>
                        <img src={require('../assets/HDR_Photo1.png')} className={"slider-item"}/>
                        <img src={require('../assets/HDR_Photo2.png')} className={"slider-item"}/>
                        <img src={require('../assets/HDR_Photo3.png')} className={"slider-item"}/>
                    </Slider>
                        <div className={"slider-image-title"}>Aplikacja DZIWNÓW4FUN urozmaici Twoje wakacje nad morzem!</div>
                        <div className={"slider-image-text"}>Pobierz już teraz:</div>
                        <div className={"slider-buttons"}>
                            <div className={"slider-buttons-button play"}></div>
                            <div className={"slider-buttons-button store"}></div>
                        </div>
                </div>
                <div className={"slider-phone-container"}>
                    <div className={"slider-phone"}>
                        <Slider ref={phoneSlider => (this.phoneSlider = phoneSlider)}{...settingsPhone}>
                            <div className={"slider-phone-item jak-grac-1"}/>
                            <div className={"slider-phone-item jak-grac-2"}/>
                            <div className={"slider-phone-item jak-grac-3"}/>
                        </Slider>
                        <div className={"slider-controls"}>
                            <div className={"slider-controls-arrow prev"} onClick={this.prevSlide}></div>
                            <div className={"slider-controls-text-container"}>
                                <div className={"slider-controls-text"}>
                                    {this.getText(this.state.counter % 3)}
                                </div>
                            </div>
                            <div className={"slider-controls-arrow next"} onClick={this.nextSlide}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SliderComponent;