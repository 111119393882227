export default (state = {shopLocations: null}, action) => {
    switch(action.type) {
        case "FETCH_SHOP_LOCATIONS_FULFILLED":{
            return {
                ...state,
                shopLocations: action.payload.data
            }
        }
        case "DELETE_SHOP_LOCATION_FULFILLED":{
            return {
                ...state,
            }
        }
        case "CREATE_SHOP_LOCATION_FULFILLED":{
            return {
                ...state,
            }
        }
        default: return state;
    }
}
