import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import logger from 'redux-logger';
import ReduxThunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import reducers from './reducers';

import MainBody from './MainBody';
import AdministrationPanel from './AdministrationPanel.js';

const store = createStore(
    reducers,
    applyMiddleware(logger, ReduxThunk, promiseMiddleware())
)

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <Switch>
                <Route exact strict path={"/manager"} component={AdministrationPanel}/>
                <Route path={"/"} component={MainBody}/>
            </Switch>
        </HashRouter>
    </Provider>
    , document.getElementById('root'));