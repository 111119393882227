import React, {Component} from 'react';
import './Download.css';

class Download extends Component{
    render(){
        return (
            <div>
                <div className="ico">
                </div>
                <div className="textCenter">
                    Kolejność dowolna. Jesteś gotowy?<br/><span style={{color: '#0077C7', fontSize: '50px'}}>START!</span>
                </div>
                <div className="imgs">
                    <p>Pobierz aplikację już teraz:</p>
                    <a href="#"><img src={require('../assets/btn_img_Google Play_2.svg')}></img></a>
                    <a href="#"><img src={require('../assets/btn_img_App Store 2.svg')}></img></a>
                </div>
            </div>
        )
    }
}

export default Download;