export default (state = {logUser: null}, action) => {
    switch(action.type) {
        case "LOGIN_USER_FULFILLED":{
            return {
                ...state,
                logUser: action.payload.headers.authorization
            }
        }
        default: return state;
    }
}
