import React, {Component} from 'react';
import './TopBar.css';
import jump from 'jump.js';

class TopBar extends Component{

    showSlider(){
        document.getElementById('mySidenav').style.width = "200px";
        document.getElementById('mySidenav').style.borderRight = "5px solid #555555";

    }

    hideSlider(){
        document.getElementById('mySidenav').style.width = "0";
        document.getElementById('mySidenav').style.borderRight = "none";

    }

    jumpTo(element){
        this.hideSlider()
        jump(element);
    }

    render(){
        return (
            <div className={"top-bar-container"}>
                <div className={"top-bar-menu"}>
                    <ul>
                        <li onClick={() => {jump('.slider-component-container')}}>JAK GRAĆ</li>
                        <li onClick={() => {jump('.shops-container')}}>SKLEPY</li>
                        <li onClick={() => {jump('.map-container-component')}}>MAPA</li>
                        <li onClick={() => {jump('.copyright')}}>REGULAMIN</li>
                    </ul>
                </div>
                <div className={"burger"} onClick={() => {this.showSlider()}}>
                    <div className={"burger-strip first"}></div>
                    <div className={"burger-strip second"}></div>
                    <div className={"burger-strip third"}></div>
                </div>
                <div id="mySidenav" className="sidenav">
                    <a href="javascript:void(0)" className="closebtn" onClick={() => {this.hideSlider()}}>&times;</a>
                    <ul>
                        <li onClick={() => {this.jumpTo('.slider-component-container')}}>JAK GRAĆ</li>
                        <li onClick={() => {this.jumpTo('.shops-container')}}>SKLEPY</li>
                        <li onClick={() => {this.jumpTo('.map-container-component')}}>MAPA</li>
                        <li onClick={() => {this.jumpTo('.copyright')}}>REGULAMIN</li>
                    </ul>
                </div>
                <div className={"image-container"}></div>
            </div>
        )
    }
}

export default TopBar;