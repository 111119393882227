import React, {Component} from 'react';
import './Footer.css';

class Footer extends Component{
    render(){
        return (
            <div className={"id-footer"} style={{color: '#5B5B5B'}}>
                <div className="left">
                    <div className="name">Urząd Miejski Dziwnów</div>
                    <div className="contact">
                        <div className="boxUP box">ul. Szosowa 5</div>
                        <div className="boxUP">Tel.: 91 32 75 163</div>
                        <br/>
                        <div className="boxDOWN">72-420 Dziwnów</div>
                        <div className="boxDOWN">E-mail: um@dziwnow.pl</div>
                    </div>
                </div>
                <div className="center">
                    <div className="btn">POBIERZ REGULAMIN</div>
                </div>
                <div className="right">
                    <div className="copyright">Copyright © Urząd Miejski Dziwnów 2019</div>
                </div>
            </div>
        )
    }
}

export default Footer;