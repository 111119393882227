import React, { Component } from 'react';

import './AdministrationPanel.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from './actions/userActions.js';
import * as shopLocationActions from './actions/shopLocationActions.js';



class AdministrationPanel extends Component{

    constructor(){
        super();
        this.state = {
            username: "",
            password: "",
            logged: false,
            name: "",
            address: "",
            city: ""
        }
    }

    componentDidMount() {
            let exp;
            if(localStorage.getItem('userLogged') != undefined){
                exp = localStorage.getItem('userTime').toString();
            } else {
                exp = 0;
            }
            let today = new Date().getTime().toString();

            for(let i = exp.length; i < today.length; i++) {
                exp = exp + "0";
            }
            if(today > exp){
                this.logout();
            }

        if (localStorage.getItem("isLogged") === "true") {
            this.setState({logged: true})
        } else {
            this.setState({logged: false})
        }
        this.props.actions2.getAllLocations()
    }

    retriveDataFromToken(){
        let name = localStorage.getItem('token').split(".")[1];
        name = Buffer.from(name, 'base64').toString("ascii");
        localStorage.setItem('userLogged', JSON.parse(name).sub)
        localStorage.setItem('userTime', JSON.parse(name).exp)
    }

    renderAllPlaces(){
        if(!this.props.shopLocations){
            console.log("brak danych")
        } else {
            console.log(this.props.shopLocations)
            return(
                this.props.shopLocations.map((shop, index) => {
                    return (
                        <div className={"managment-panel-list-item"}>
                            <div className={"labels-container"}>
                                <div className={"label"}>Nazwa sklepu: <b>{shop.name}</b></div>
                                <div className={"label"}>Miasto sklepu: <b>{shop.city}</b></div>
                                <div className={"label"}>Adres sklepu: <b>{shop.address}</b></div>
                            </div>
                            <div className={"button-container"}>
                                <div className={"list-button"} onClick={() => {this.deleteLocation(shop.id)}}></div>
                            </div>
                        </div>
                    )
                })
            )
        }
    }

    deleteLocation(id){
        this.props.actions2.deleteLocation(id).then(() => {this.props.actions2.getAllLocations()})
    }

    renderLoginWindow(isLogged){
        if(!isLogged)
        return(
            <div className={"login-window-container"}>
                <div className={"login-window-title"}>Zaloguj sie!</div>
                <div className={"login-window-input"}>
                    <div className={"login-window-label"}>Login :</div>
                    <div className={"login-window-prompt"}>
                        <input type="text" onChange={(event) => this.setState({ username: event.target.value })} value={this.state.username}/><br/>
                    </div>
                </div>
                <div className={"login-window-input"}>
                    <div className={"login-window-label"}>Password :</div>
                    <div className={"login-window-prompt"}>
                        <input type="password" onChange={(event) => this.setState({ password: event.target.value })} value={this.state.password}/><br/>
                    </div>
                </div>
                <div className={"login-window-button"} onClick={() => {this.login()}}>Zaloguj</div>
            </div>
        )
    }

    renderManagmentPanel(isLogged){
        if(isLogged)
            return(
                <div className={"managment-panel-container"}>
                    <div className={"managment-panel-header"}>
                        <div className={"managment-panel-header-label"}>Panel Administracyjny</div>
                        <div className={"managment-panel-header-logout"}><span onClick={() => {this.logout()}}>Wyloguj</span></div>
                    </div>
                    <div className={"managment-panel-create"}>
                        <div className={"managment-panel-create-header"}>Dodaj lokalizacje sklepu</div>
                        <div className={"managment-panel-create-item"}>
                            Nazwa sklepu:
                            <input type={"text"} onChange={(event) => this.setState({ name: event.target.value })} value={this.state.name}/>
                        </div>
                        <div className={"managment-panel-create-item"}>
                            Miasto:
                            <input type={"text"} onChange={(event) => this.setState({ city: event.target.value })} value={this.state.city}/>
                        </div>
                        <div className={"managment-panel-create-item"}>
                            Adres sklepu:
                            <input type={"text"} onChange={(event) => this.setState({ address: event.target.value })} value={this.state.address}/>
                        </div>
                        <div className={"managment-panel-create-item"}>
                            <div className={"managment-panel-create-button"} onClick={() => {this.createLocation()}}>Dodaj</div>
                        </div>
                    </div>
                    <div className={"mangament-panel-list"}>
                        {this.renderAllPlaces()}
                    </div>

                </div>
            )
    }

    createLocation(){
        let sl = {
            "name": this.state.name,
            "city": this.state.city,
            "address": this.state.address
        }

        this.props.actions2.createLocation(sl).then(() => {this.props.actions2.getAllLocations()});
        this.setState({name: "", city: "", address: ""})
    }

    login(){
        let user = {
            "username": this.state.username,
            "password": this.state.password
        }

        this.props.actions.loginUser(user).then(() => {
            localStorage.setItem("isLogged", "true");
            localStorage.setItem("token", this.props.logUser)
            this.retriveDataFromToken();
            this.setState({logged: true})
        });
    }

    logout(){
        localStorage.clear();
        this.setState({logged: false});
        this.setState({username: "", password: ""})
    }

    render(){
        return (
            <div>
                {this.renderLoginWindow(this.state.logged)}
                {this.renderManagmentPanel(this.state.logged)}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(userActions, dispatch),
        actions2: bindActionCreators(shopLocationActions, dispatch)
    }
}

const mapStateToProps = (state) => {
    return {
        logUser: state.user.logUser,
        shopLocations : state.shopLocation.shopLocations
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationPanel);