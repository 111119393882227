import React,{Component} from 'react';

import './Map.css';

class Map extends Component{
    render() {
        return (
            <div className={"map-container-component"}>
                <div className="map-ico">
                </div>
                <div className="text">A gdzie łapać punkty?</div>
                <div className="map"></div>
            </div>
        )
    }
}

export default Map;