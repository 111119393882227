import { combineReducers } from 'redux';

import userReducer from './userReducer.js';
import shopLocationReducer from './shopLocationReducer.js'

const rootReducer = combineReducers({
    user: userReducer,
    shopLocation: shopLocationReducer
})

export default rootReducer;