import React, { Component } from 'react';
import './MainBody.css';

import TopBar from './components/TopBar.js';
import SliderComponent from './components/SliderComponent.js';
import Shops from './components/Shops.js';
import Map from './components/Map.js';
import Download from './components/Download.js';
import Footer from './components/Footer.js';

class MainBody extends Component {
    constructor(props){
        super(props);
    }

    render() {
    return (
      <div className="App">
        <header><TopBar /></header>
        <section>
          <article><SliderComponent /></article>
          <article><Shops /></article>
          <article><Map /></article>
          <article><Download /></article>
        </section>
        <footer><Footer /></footer>
      </div>
    );
  }
}

export default MainBody;
